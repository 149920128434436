<template>
  <div id="tasks">

    <a-button class="button" type="primary" @click="this.$refs.taskGrid.autoSizeAll()">
      Resize
    </a-button>
    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>
    <a-input v-model:value="limit" :maxlength="4" placeholder="limit" style="width: 120px"/>

    <TaskGrid :tasks="tasks" ref="taskGrid"/>
    <h3>Total items: {{ totalItems }}</h3>
  </div>

</template>

<script>

import TaskGrid from "@/components/TaskGrid.vue";
import {apiUrl, TLSEnabled} from "@/config/api";

export default {
  components: {
    TaskGrid
  },
  props: ['keycloak'],
  data() {
    return {
      //roles: this.keycloak.resourceAccess.erasmus.roles,
      tasks: [],
      totalItems: 0,
      limit: null
    }
  },
  created() {
    this.refreshQuery()
  },
  methods: {
    refreshQuery(){
      function getLimit(limit) {
        if (limit)
          return "?limit=" + limit
        else
          return ""
      }
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/tasks" + getLimit(this.limit)
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.totalItems = responseJson.count
            this.tasks = responseJson.results
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }

}
</script>

<style>

#app {
  margin: 60px 20px;
}

#table {
  margin-top: 20px;
}

#logout {
  float: right;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>