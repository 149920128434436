<template>
  <div id="app">
    <h1 :class="beamConnected" >{{ title }}</h1>

    <a-button id="logout" type="dashed" @click="logout">
      Logout
    </a-button>

    <a-tabs v-model:activeKey="tabKey">
      <a-tab-pane key="1" tab="Beams">
        <beams :keycloak='keycloak'  @beamConnectStatus="beamConnectStatus" @changeTasksTab="changeTasksTab"/>
        <!-- @changeQueryTab="changeQueryTab"  -->
      
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="Queries">
        <query :keycloak='keycloak' :bus="queriesBus"/>
      </a-tab-pane> -->
      <a-tab-pane key="3" tab="Tasks">
        <TaskQuery :keycloak='keycloak' :bus="tasksBus"/>
      </a-tab-pane>
    </a-tabs>

  </div>

</template>

<script>


import beams from "@/Beams";
//import query from "@/Query";
import TaskQuery from "@/components/TaskQuery.vue";
import mitt from 'mitt';
import {authEnabled} from "@/config/api";

export default {
  name: 'app',
  components: {
    beams,
    //query,
    TaskQuery
  },
  props: ['keycloak'],
  data() {
    return {
      connected: false,
      queriesBus: mitt(),
      tasksBus: mitt(),
      tabKey: "1",
      beamName: "",
    }
  },
  computed: {
    title() {
      if (this.connected)
        return "Beam Erasmus Connected " + this.beamName
      else
        return "Beam Erasmus Disconnected"
    },
    beamConnected () {
      if (this.connected) {
        return 'beamStatusConnected';
      }

      return 'beamStatusDisconnected';
    }
  },
  created() {
    this.tabKey = "2",
    this.tabKey = "3"
  },
  mounted() {
    this.tabKey = "1"
  },
  methods: {
    beamConnectStatus(connected){
      console.log("connected")
      this.connected = connected
    },
    // changeQueryTab(newId, name) {
    //   console.log("ID connection: " + newId)
    //   this.beamName = name
    //     this.queriesBus.emit('wsConnected', newId)
    //   this.tabKey = "2"
    // },
    changeTasksTab(newId, name) {
      console.log("ID connection: " + newId)
      this.beamName = name
      //this.tasksBus.emit('wsConnected', newId)
      this.tabKey = "3"
    },
    logout() {
      if (authEnabled)
        this.keycloak.logout()
    }
  }

}
</script>


<style>

.beamStatusConnected {
  color: blue
}

.beamStatusDisconnected {
  color: red
}

#app {
  margin: 20px 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>
