<template>
  <div id="task_grid">

    <ag-grid-vue
        id="table"
        style=" height: 700px"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="tasks"
        :gridOptions="gridOptions"
        :getRowStyle="getRowStyle"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-drawer
        width="1400"
        id="drawer"
        placement="right"
        :closable=false
        :maskClosable=true
        :visible="jsonVisible"
        @close="onCloseJsonViewer"

    >
      <json-viewer
          :expanded=true
          :expand-depth=4
          copyable
          boxed
          :value="jsonRendered">
      </json-viewer>

    </a-drawer>


  </div>
</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import JsonViewer from 'vue-json-viewer'
import moment from "moment";

export default {
  components: {
    AgGridVue,
    JsonViewer
  },
  props: ['roles', 'tasks'],
  data() {
    return {
      jsonRendered: "",
      jsonVisible: false,
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        onCellClicked: this.onCellClicked,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [
        {
          headerName: 'Id',
          field: 'id',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
          checkboxSelection: true,
          headerCheckboxSelection: true
        },
        {
          headerName: 'Priority',
          field: 'priority',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'name',
          field: 'name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'status',
          field: 'status',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'created',
          field: 'created',
          sortable: true,
          cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY HH:mm')
          },
          comparator: (valueA, valueB) => {
            const time1 = Date.parse(valueA)
            const time2 = Date.parse(valueB)
            return time2 - time1
          }
        },
        {
          headerName: 'run',
          field: 'run',
          sortable: true,
          cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY HH:mm')
          },
          comparator: (valueA, valueB) => {
            const time1 = Date.parse(valueA)
            const time2 = Date.parse(valueB)
            return time2 - time1
          }
        },
        {
          headerName: 'duration',
          field: 'duration',
          cellRenderer: (data) => {
            const duration = data.value
            let milliseconds = parseInt((duration%1000)/100)
                , seconds = parseInt((duration/1000)%60)
                , minutes = parseInt((duration/(1000*60))%60)
                , hours = parseInt((duration/(1000*60*60))%24);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            return hours + ":" + minutes + ":" + seconds + "," + milliseconds;
          },
          sortable: true
        },
        {
          headerName: 'msg',
          field: 'msg',
          cellStyle: {'white-space': 'normal'},
          width: 700,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'payload',
          field: 'payload',
          cellStyle: {'white-space': 'normal'},
          width: 300,
          filter: 'agTextColumnFilter',
        }
      ]
    }
  },
  methods: {
    getSelectedNodes() {
      return this.gridApi.getSelectedNodes();
    },
    autoSizeAll() {
      const allColumnIds = [];
      this.gridColumnApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
      if (this.roles.includes("host")) {
        this.columns.splice(1, 0, {
              headerName: 'user',
              field: 'userName',
              sortable: true,
              filter: 'agTextColumnFilter',
              filterParams: {buttons: ['reset', 'apply']},
            },
        )
        this.gridApi.setColumnDefs(this.columns)
      }
    },
    getRowStyle: (params) => {
      switch (params.data.status) {
        case "FAIL":
          return {'color': 'red'}
        case "DONE":
          return {'color': 'green'}
        default:
          return {'color': 'blue'}
      }
    },
    onCellClicked(event) {
      try {
        const json = JSON.parse(event.value)
        this.jsonVisible = true
        this.jsonRendered = json
      } catch (e) { // eslint-disable-next-line no-use-before-define
      }

    },
    onCloseJsonViewer() {
      this.jsonVisible = false;
      this.jsonRendered = ""

    }
  }
}

export class TaskGrid {
}
</script>

<style scoped>

</style>