<template>
  <div id="beams">
      <a-popconfirm
              title="Are you sure delete this beam?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="remove"
      >
          <a-button id="remove" type="primary">
              Remove
          </a-button>
      </a-popconfirm>

      <!-- <a-button id="queries" type="primary" @click="queries">
          Queries
      </a-button> -->
      <!-- <a-button id="tasks" type="primary" @click="tasks">
          Tasks
      </a-button> -->


    <ag-grid-vue
        id="table"
        :style="{'height':tableHeight}"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="beams"
        :gridOptions="gridOptions"
        :getRowStyle="getRowStyle"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-drawer
        width="1400"
        id="drawer"
        placement="right"
        :closable=false
        :maskClosable=true
        :visible="jsonVisible"
        @close="onCloseJsonViewer"

    >
      <json-viewer
          :expanded=true
          :expand-depth=4
          copyable
          boxed
          :value="jsonRendered">
      </json-viewer>

    </a-drawer>
  </div>

</template>

<script>
import {apiUrl, TLSEnabled} from "@/config/api";
import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import JsonViewer from 'vue-json-viewer'
import moment from "moment";
import {notification} from "ant-design-vue";

export default {
  name: 'beam_clients',
  components: {
    AgGridVue,
    JsonViewer
  },
  props: ['keycloak'],
  beforeMount() {
    this.tableHeight = window.screen.height * 0.70 + "px"
  },
  data() {
    return {
      tableHeight: "",
      socket: {},
      jsonRendered: "",
      jsonVisible: false,
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        // rowSelection: 'multiple',
        // rowMultiSelectWithClick: true,
        pagination: true,
        paginationAutoPageSize: true,
        onCellClicked: this.onCellClicked,
        // onRowClicked: this.onRowClicked,
        getRowId: (item) => {
          return item.data.id;
        }
      },
      defaultColDef: {
        filter: true,
        wrapText: false,
        autoHeight: true,
        enableCellChangeFlash: true
      },
      lastAlive: Date.now(),
      aliveTimer: null,
      beams: [],
      columns: [
        {
          headerName: 'Id',
          sortable: true,
          width: 150,
          field: 'id',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
          checkboxSelection: true
        },
        {
          headerName: 'Name',
          sortable: true,
          width: 250,
          field: 'name',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Connection',
          sortable: true,
          width: 150,
          field: 'connectionName',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        // {
        //   headerName: 'Ip',
        //   width: 100,
        //   field: 'ip',
        //   filter: 'agTextColumnFilter',
        //   filterParams: {buttons: ['reset', 'apply']},
        // },
        {
          headerName: 'Connected',
          sortable: true,
          width: 120,
          field: 'connected',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
          enableCellChangeFlash: true
        },
        {
          headerName: 'Status',
          width: 138,
          field: 'status',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'V.Cli.',
          width: 100,
          field: 'versionClient',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'V.Sup.',
          width: 100,
          field: 'versionSupervisor',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'V.Upd.Cli.',
          width: 120,
          field: 'versionUpdateClient',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'V.Upd.Sup.',
          width: 120,
          field: 'versionUpdateSupervisor',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Repair',
          width: 115,
          field: 'repair',
          cellRenderer: params => {
            return `<input type='checkbox' ${params.value ? 'checked' : 'unchecked'} style="pointer-events: none;" />`;
          },
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Active',
          width: 115,
          field: 'active',
          cellRenderer: params => {
            return `<input type='checkbox' ${params.value ? 'checked' : 'unchecked'} style="pointer-events: none;" />`;
          },
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: { buttons: ['reset', 'apply']}
        },
        {
          headerName: 'LastLog',
          width: 1000,
          field: 'lastLog',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'LastError',
          width: 1000,
          field: 'lastError',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Time',
          width: 200,
          field: 'beamTime',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'ServerName',
          width: 200,
          field: 'beamServerName',
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'LastConnection',
          width: 150,
          field: 'lastConnection',
          sortable: true,
          cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY HH:mm')
          },
          comparator: (valueA, valueB) => {
            const time1 = Date.parse(valueA)
            const time2 = Date.parse(valueB)
            return time2 - time1
          }
        },
        {
          headerName: 'LastConnection Super',
          width: 200,
          field: 'lastConnectionSupervisor',
          sortable: true,
          cellRenderer: (data) => {
            console.log(data.value)
            if ((typeof data.value !== 'undefined'))
              return moment(data.value).format('DD/MM/YYYY HH:mm')
            else
              return ""
          },
          comparator: (valueA, valueB) => {
            const time1 = Date.parse(valueA)
            const time2 = Date.parse(valueB)
            return time2 - time1
          }
        },
        {
          headerName: 'LastDisConnection',
          width: 180,
          field: 'lastDisConnection',
          sortable: true,
          cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY HH:mm')
          },
          comparator: (valueA, valueB) => {
            const time1 = Date.parse(valueA)
            const time2 = Date.parse(valueB)
            return time2 - time1
          }
        },
        {
          headerName: 'Created',
          width: 150,
          field: 'created',
          sortable: true,
          cellRenderer: (data) => {
            return moment(data.value).format('DD/MM/YYYY HH:mm')
          },
          comparator: (valueA, valueB) => {
            const time1 = Date.parse(valueA)
            const time2 = Date.parse(valueB)
            return time2 - time1
          }
        },
        {
          headerName: 'OS',
          width: 120,
          field: 'os',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
      ]
    }
  },
  created() {
    this.wsConnected()
  },
  methods: {
    wsConnected(){
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "ws" + tls + "://" + apiUrl + "/user/subscribe/beams"
      console.log(uri)
      const token = "Bearer%20" + this.keycloak.token
      console.log(token)
      this.socket = new WebSocket(uri, token )
      this.aliveTimer = setInterval(() => {
        const checkTimeAlive = this.lastAlive + 90000
        if (checkTimeAlive < Date.now()) {
          this.socket.close()
        }
      }, 30000)
      this.socket.onopen = () => {
        this.lastAlive = Date.now()
        this.$emit('beamConnectStatus', true)
      }
      this.socket.onmessage = (data) => {
        const item = JSON.parse(data.data)
        if (data.data === "{\"command\":\"ALIVE\"}") {
          this.lastAlive = Date.now()
          this.socket.send(data.data)
        }
        else if ("created" in item) {
          const datasource = this.beams.slice()
          const index = datasource.findIndex((task => task.id === item.id))
          item.lastEvent = moment().format('DD/MM/YYYY HH:mm:ss')
          if (index >= 0)
            datasource[index] = item
          else
            datasource.push(item)
          this.beams = datasource
        }
      }
      this.socket.onclose = () => {
        clearInterval(this.aliveTimer)
        this.$emit('beamConnectStatus', false)
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'id', sort: 'asc'}
      ];
      const filterActive = { active :{
        "type": "equals",
        "filter": true
      }}
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
      this.gridApi.setFilterModel(filterActive);
    },
    dateComparator(date1, date2) {
      return new Date(date1).getTime() < new Date(date2).getTime() ? -1 : 1;
    },
    getRowStyle: (params) => {
      switch (params.data.connected) {
        case false:
          return {'color': 'orange'}
      }
      switch (params.data.status) {
        case "UNREGISTERED":
          return {'color': 'orange'}
        case "UNREADY":
          return {'color': 'red'}
        case "READY":
          return {'color': 'green'}
        default:
          return {'color': 'blue'}
      }
    },
    onCellClicked(event) {
      if (event.colDef.field === "lastLog") {
        try {
          const json = JSON.parse(event.value)
          this.jsonVisible = true
          this.jsonRendered = json
        } catch (e) { // eslint-disable-next-line no-use-before-define
        }
      }
    },
    //onRowClicked(event) {
    //  console.log("row click")
    //  this.$emit('changeQueryTab', event.data.id, event.data.name)
    //},
    onCloseJsonViewer() {
      this.jsonVisible = false;
      this.jsonRendered = ""
    },
    queries() {
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes) {
        console.log("selected Nodes")
        const node = selectedNodes[0]
        this.$emit('changeQueryTab', node.data.id, node.data.name)
      }
    },
    // tasks() {
    //   const selectedNodes = this.gridApi.getSelectedNodes()
    //   if (selectedNodes) {
    //     console.log("selected Nodes")
    //     const node = selectedNodes[0]
    //     //this.$emit('changeTasksTab', node.data.id, node.data.name)
    //     this.$emit('changeTasksTab', node.data.id, node.data.name)
    //     console.log("changeTasksTab")
    //   }
    // },
    errorNotification(msg) {
        notification.open({
            message: 'Error',
            description: msg,
            class: 'error-class',
        });
    },
    remove() {

        const selectedNodes = this.gridApi.getSelectedNodes()
        if (selectedNodes) {
            const node = selectedNodes[0]
            const tls = (TLSEnabled) ? "s" : ""
            const uri = "http" + tls + "://" + apiUrl + "/beamclient/" + node.data.id
            const token = "Bearer " + this.keycloak.token
            fetch(uri, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token
                }
            })
            .then(async response => {
                if (response.ok) {
                    this.beams.splice(this.beams.findIndex(({id}) => id === node.data.id), 1);
                }
                else {
                    const error = (await response.text() || response.statusText)
                    return Promise.reject(error)
                }
            })
            .catch(error => {
                this.errorNotification(error)
            })
        }
    }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
}
.ant-btn-primary {
    margin-right: 10px
}
html::-webkit-scrollbar {
  display: none;
}

</style>