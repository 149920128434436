import { createApp } from 'vue'
import App from './App.vue'
import Keycloak from "keycloak-js"
import {apiAuthorizeUrl, authEnabled} from "@/config/api"
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';


    if (authEnabled) {
        let initOptions = {
            url: apiAuthorizeUrl, realm: 'axesor', clientId: 'erasmus-front', onLoad: 'login-required'
        }
        const keycloak = new Keycloak(initOptions)
        keycloak
            .init({onLoad: initOptions.onLoad})
            .then((auth) => {
                if (!auth) {
                    window.location.reload()
                } else
                    createApp(App, {keycloak: keycloak})
                        .use(Antd)
                        .mount('#app')

                setInterval(() => {
                    keycloak
                        .updateToken(70)
                        .catch(() => {
                            console.log('Failed to refresh token')
                        })
                }, 6000)

            }).catch((error) => {
            console.log(error)
            console.log("Authenticated Failed")
        })
    }
    else {
        const keycloak = {
            token: "fake",
            resourceAccess: {}
        }
        createApp(App,{keycloak: keycloak})
            .use(Antd)
            .mount('#app')
    }

