export const apiAuthorizeUrl = 'KEYCLOAK_URL_ENV'
export const apiUrl = 'REST_API_URL_ENV'
export const authEnabled = eval("AUTH_ENABLED_KEYCLOAK_ENV")
export const TLSEnabled = eval("TLS_ENABLED_ENV")

// export const apiAuthorizeUrl = 'localhost:8080'
// export const apiUrl = 'localhost:9002'
// export const authEnabled = false
// export const TLSEnabled = false

// export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
// export const apiUrl = 'wss://erasmus.dev.gc.axesor.com'

//export const apiAuthorizeUrl = 'http://localhost:8080/'
//export const apiUrl = 'ws://localhost:9002'
